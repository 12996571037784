@media only screen and (min-device-width: 1024px) {
  .fixTabletInfoMargin {
    margin-top: 5.5rem !important;
  }
  .fixTabletInfoImage {
    width: 100%;
    padding-top: 3rem !important;
    padding-right: 0px;
  }
  .fixInfoColumnsMarginTablet {
    margin-top: 8% !important;
  }
  .fixInfoColumnsMargin {
    margin-top: 4% !important;
  }
  .fixDesktopInfoMargin {
    margin-top: 1% !important;
  }
}

@media only screen and (min-device-width: 1366px) {
  .fixInfoColumnsMarginTablet {
    margin-top: 14% !important;
  }
  .fixTabletInfoMargin {
    margin-top: 8.5rem !important;
  }
  .fixInfoColumnsMargin {
    margin-top: 3% !important;
  }
}

@media only screen and (min-device-width: 1367px) {
  .fixInfoColumnsMargin {
    margin-top: 8% !important;
  }
  .fixTabletInfoImage {
    width: 100%;
    padding-top: 3rem !important;
    padding-right: 50px;
  }
  .fixInfoColumnsPadding {
    padding-left: 100px;
    padding-right: 100px;
  }
  .fixDesktopInfoMargin {
    margin-top: 3.25% !important;
  }
}

.fixCol6Center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
}

.infoContainerWhite {
  background: white;
}