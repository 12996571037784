.MuiPopover-root > .MuiMenu-paper {
  top: 58px!important;
}
.MuiList-padding {
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}
.MuiDrawer-paperAnchorBottom {
  bottom: 56px!important;
  box-shadow: none!important;
}
.MuiBottomNavigation-root {
  z-index: 9999!important;
}
.MuiListItem-gutters > svg {
  margin-right: 5px;
  margin-bottom: 4px;
}
@media only screen and (max-device-width: 736px) {
  .MuiToolbar-gutters > div {
    height: 0px!important;
  }
  .MuiButtonBase-root.MuiIconButton-root {
    bottom: 13px;
  }
}
@media only screen and (max-device-width: 823px) and (orientation: landscape) {
  .MuiPaper-root.MuiMenu-paper {
    margin-left: 24px;
  }
}
@media only screen and (max-device-width: 414px) and (orientation: portrait) {
  .MuiPaper-root.MuiMenu-paper {
    margin-left: 16px;
  }
}