/* datepicker */
.react-datepicker-wrapper,
.react-datepicker-wrapper > div,
.react-datepicker-wrapper > div > input {
  width: 100%;
  height: 47px;
  border: 0px;
  border-radius: 6px;
}
.react-datepicker-wrapper > div > input {
  font-size: 16px;
  font-weight: 400;
  padding-left: 12px;
  font-family: revert;
}
.datepicker-dark {
  background-color: #11151b;
  color: #ff7096;
}
.datepicker-normal {
  background-color: hsl(0, 0%, 91%);
  color: #222;
}
.datepicker-normal:hover, .datepicker-dark:hover {
  background-color: #00000021;
}
@media only screen and (max-device-width: 812px) {
  .col-date-left {
    padding-left: 0px;
    padding-right: 0px;
  }
  .col-date-right {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (min-device-width: 813px) {
  .col-date-left {
    padding-left: 0px;
    padding-right: 8px;
  }
  .col-date-right {
    padding-left: 8px;
    padding-right: 0px;
  }
}

/* datepicker calendar */
.react-datepicker__day:hover {
  color: #fff;
  background-color: #f76882;
}
.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
  background-color: #f54a69 !important;
}
.react-datepicker__day--disabled:hover {
  color: #ccc;
  background-color: #fff;
}
.col-date-normal > .react-datepicker__tab-loop > .react-datepicker-popper > div
  > .react-datepicker,
.col-date-normal > .react-datepicker__tab-loop > .react-datepicker-popper > div
  > .react-datepicker > .react-datepicker__month-container > .react-datepicker__header {
    border: 1px solid #f0f0f0;   
}
.col-date-normal > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__triangle:before,
.col-date-normal > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__triangle:after {
    border-top-color:#f0f0f0;
    border-bottom-color:#f0f0f0;
}

/* datepicker calendar darkmode */
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div {
  background-color: #161b22;
  border: 1px solid #0d1117;
}
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__month-container > .react-datepicker__header {
    background-color: #0d1117;
}
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__month-container > .react-datepicker__header > div,
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__month-container > .react-datepicker__header > div > div {
    color: #ddd;
}
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__month-container > .react-datepicker__header {
    border-bottom: 1px solid #0d1117;
}
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__month-container > .react-datepicker__month > .react-datepicker__week 
    > .react-datepicker__day--disabled {
      color: rgb(92, 92, 92) !important;
}
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__month-container > .react-datepicker__month > .react-datepicker__week 
    > .react-datepicker__day {
      color: rgb(187, 187, 187);
}
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__month-container > .react-datepicker__month > .react-datepicker__week
    > .react-datepicker__day--selected {
      color: #ddd !important;
}
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__month-container > .react-datepicker__month > .react-datepicker__week
    > .react-datepicker__day:hover {
      background-color: #f76882;
      color: #ddd !important;
}
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__month-container > .react-datepicker__month > .react-datepicker__week
    > .react-datepicker__day--disabled:hover {
      color: rgb(92, 92, 92) !important;
      background-color: #161b22;
}
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__triangle:before,
.col-date-dark > .react-datepicker__tab-loop > .react-datepicker-popper > div > div
  > .react-datepicker__triangle:after {
    border-top-color: #0d1117;
    border-bottom-color: #0d1117;
}